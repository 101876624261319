import React from 'react';
import img1 from '../images/research.jpg'; 
import img2 from '../images/stand.jpg';
import img3 from '../images/team.jpg';
import img4 from '../images/assur.jpg';

const Services = () => {
    return (
        <div id="services" className="bg-gray-100 pt-7 pb-10">
            {/* What We Offer Section */}
            <section className="mt-8 py-12" data-aos="fade-up">
                <div className="text-center mb-8">
                    <h3 className="text-4xl font-extrabold text-maroon leading-tight tracking-tight">What We Offer</h3>
                </div>
                <div className="container mx-auto px-8 lg:px-16">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    
                        <div className="relative group overflow-hidden rounded-xl shadow-lg transform transition-transform hover:scale-105 hover:shadow-2xl border border-gray-200">
                            <img alt="Chemical Formulations" className="w-full h-72 object-cover transition-transform duration-500 group-hover:scale-110" src={img1} />
                            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent opacity-80 p-6 rounded-b-lg">
                                <h4 className="text-lg font-bold text-white mb-1">CHEMICAL FORMULATIONS</h4>
                                <p className="text-sm text-white">
                                    We develop advanced chemical formulations that enhance textile manufacturing processes.
                                </p>
                            </div>
                        </div>

                        <div className="relative group overflow-hidden rounded-xl shadow-lg transform transition-transform hover:scale-105 hover:shadow-2xl border border-gray-200">
                            <img alt="Updated Industry Standards" className="w-full h-72 object-cover transition-transform duration-500 group-hover:scale-110" src={img2} />
                            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent opacity-80 p-6 rounded-b-lg">
                                <h4 className="text-lg font-bold text-white mb-1">UPDATED STANDARDS</h4>
                                <p className="text-sm text-white">
                                    We adhere to and continually update our practices to meet the highest textile industry standards.
                                </p>
                            </div>
                        </div>

                        <div className="relative group overflow-hidden rounded-xl shadow-lg transform transition-transform hover:scale-105 hover:shadow-2xl border border-gray-200">
                            <img alt="Dedicated Chemical Experts" className="w-full h-72 object-cover transition-transform duration-500 group-hover:scale-110" src={img3} />
                            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent opacity-80 p-6 rounded-b-lg">
                                <h4 className="text-lg font-bold text-white mb-1">DEDICATED CHEMICAL EXPERTS</h4>
                                <p className="text-sm text-white">
                                    Our team of experienced chemists and technologists is committed to delivering superior textile solutions.
                                </p>
                            </div>
                        </div>

                        <div className="relative group overflow-hidden rounded-xl shadow-lg transform transition-transform hover:scale-105 hover:shadow-2xl border border-gray-200">
                            <img alt="Rigorous Quality Control" className="w-full h-72 object-cover transition-transform duration-500 group-hover:scale-110" src={img4} />
                            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent opacity-80 p-6 rounded-b-lg">
                                <h4 className="text-lg font-bold text-white mb-1">RIGOROUS QUALITY CONTROL</h4>
                                <p className="text-sm text-white">
                                    Our rigorous quality control processes ensure that every product meets stringent industry standards.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    );
}

export default Services;