import React from 'react';
import placeholderImage from '../images/lababt.jpg'; // Replace with your desired image

const Intro = () => {
    return (
        <div className="relative flex items-center justify-center m-auto max-w-7xl p-4 md:p-6" id="about">
            <div className="flex flex-col py-6 justify-center items-center text-center" data-aos="fade-up">
                
                {/* Header Section */}
                <div className="w-full mb-6">
                    <h3 className="text-4xl font-extrabold text-maroon leading-tight tracking-tight">
                        About Texcellence Specialties Private Limited
                    </h3>
                </div>
                
                {/* Image and Text Section */}
                <div className="flex flex-col lg:flex-row justify-between items-center w-full max-w-6xl space-y-6 lg:space-y-0 lg:space-x-8">
                    
                    {/* Image Section */}
                    <div className="lg:w-2/5 flex justify-center mb-4 lg:mb-0">
                        <div className="relative overflow-hidden rounded-xl shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl">
                            <img 
                                alt="Texcellence Specialties" 
                                className="w-full h-auto object-cover" 
                                src={placeholderImage} // Use the new image path
                            />
                        </div>
                    </div>
                    
                    {/* Text Section */}
                    <div className="lg:w-3/5 px-2 lg:px-4">
                        <p className="text-lg text-gray-700 font-normal mb-6 leading-relaxed text-justify">
                            Established in 2014 in Dombivli, Thane district, Maharashtra, India, Texcellence Specialties Private Limited is a leading manufacturer of specialty chemicals for the textile industry. Our products are designed to add value at every stage, from fiber to fabric and garment production. We specialize in chemicals for pre-treatment, dyeing, printing, and finishing of textiles. Committed to sustainability, our products are free from APEO, NPEO, and hazardous substances, and are 100% biodegradable.
                        </p>
                        
                        <p className="text-lg text-gray-700 font-normal leading-relaxed text-justify">
                            Our state-of-the-art production facilities are located in Dombivli MIDC and Khed MIDC at Chiplun. With a team of skilled technologists, chemists, and engineers, we offer customized products and technical solutions tailored to our customers' needs.
                        </p>
                    </div>
                </div>
            </div>
            
            {/* Decorative Elements */}
            <div className="absolute bottom-0 left-0 w-48 h-48 bg-gradient-to-r from-maroon to-red-500 rounded-full opacity-40 blur-3xl -translate-x-1/3 translate-y-1/3"></div>
            <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-r from-maroon to-yellow-500 rounded-full opacity-40 blur-3xl translate-x-1/3 -translate-y-1/3"></div>
        </div>
    );
}

export default Intro;
