import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import backgroundVideo from '../images/1.mp4'; // Make sure to add the correct path to your video file

const Hero = () => {
    return (
        <>
            <div className="hero bg-white pt-24 pb-32 relative overflow-hidden" id='hero'>
                {/* Video Background */}
                <video 
                    className="absolute top-0 left-0 w-full h-full object-cover z-0 filter-bright" 
                    src={backgroundVideo} 
                    autoPlay 
                    muted 
                    loop
                    playsInline
                />

                {/* Gradient Overlay to make text readable */}
                <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-black via-transparent to-black opacity-30 z-5"></div>

                {/* Navbar */}
                <div className="absolute top-0 left-0 w-full z-20">
                    <NavBar />
                </div>

                {/* Hero Content */}
                <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between px-4 lg:px-8 relative z-10 mt-16">
                    <div className="lg:w-1/2 flex flex-col justify-center mb-12 lg:mb-0 text-center lg:text-left">
                        <h1 className="text-5xl lg:text-7xl font-extrabold text-white leading-tight mb-4 animate-fade-in">
                            <span className="relative inline-block text-white">
                                <span className="relative z-10">Texcellence</span>
                                <span className="absolute inset-0 bg-yellow-200 transform -skew-x-12 -skew-y-6 z-0"></span>
                            </span> <br />
                            <span className="text-maroon">Always with you</span>
                        </h1>
                        <p className="text-lg lg:text-2xl text-gray-200 mb-8 animate-slide-in">
                            Leading the way in textile chemical innovation, Texcellence delivers solutions that empower industries and build lasting partnerships.
                        </p>
                        <Link to="/contact" className="text-white bg-red-600 hover:bg-white hover:text-red-600 border border-red-600 inline-flex items-center justify-center w-36 px-4 py-2 text-sm font-semibold shadow-lg rounded-full transition-all duration-200">
                            Learn More
                            <svg className="w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </Link>
                    </div>
                    <div className="lg:w-1/2 flex justify-center lg:justify-end items-center relative mb-12 lg:mb-0">
                            {/* Additional content or images can be added here */}
                    </div>
                </div>

                {/* Decorative Elements */}
                <div className="absolute top-0 left-0 w-96 h-96 bg-gradient-to-r from-yellow-400 to-yellow-200 rounded-full opacity-30 blur-3xl -translate-x-1/3 -translate-y-1/3 animate-pulse"></div>
                <div className="absolute bottom-0 right-0 w-64 h-64 bg-gradient-to-r from-maroon to-red-500 rounded-full opacity-30 blur-3xl translate-x-1/3 translate-y-1/3 animate-pulse"></div>
                <div className="absolute bottom-1/4 left-1/4 w-40 h-40 bg-red-500 rounded-full opacity-30 blur-2xl -translate-x-1/3 translate-y-1/3 animate-pulse"></div>

                {/* Scroll Down Button with Mouse Animation */}
                <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 z-20">
                    <a href="#next-section" className="scroll-down flex flex-col items-center justify-center">
                        <span className="text-sm font-semibold mb-2 text-gray-800 tracking-wide uppercase">Scroll Down</span>
                        <div className="mouse-icon flex items-center justify-center">
                            <div className="mouse-body border-2 border-gray-400 rounded-full w-8 h-14 flex items-center justify-center">
                                <div className="mouse-wheel bg-gray-400 w-2 h-4 rounded-full animate-scroll"></div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            {/* Global Theme and Animations */}
            <style jsx global>{`
                body {
                    font-family: 'Roboto', sans-serif;
                    background-color: #f7f7f7;
                    color: #333;
                }
                .hero {
                    background-color: #ffffff;
                    position: relative;
                    z-index: 1;
                }
                
                .text-maroon {
                    color: #5b2a2a; /* Maroon color from the logo */
                }
                .bg-maroon {
                    background-color: #5b2a2a; /* Maroon color from the logo */
                }
                .bg-maroon-dark {
                    background-color: #4e2323; /* Darker shade of maroon for hover effect */
                }
                .animate-slide-in {
                    animation: slideIn 1s ease-out;
                }
                .animate-fade-in {
                    animation: fadeIn 1s ease-out;
                }
                .animate-slide-up {
                    animation: slideUp 1s ease-out;
                }
                @keyframes slideIn {
                    from { opacity: 0; transform: translateX(-20px); }
                    to { opacity: 1; transform: translateX(0); }
                }
                @keyframes fadeIn {
                    from { opacity: 0; transform: translateY(20px); }
                    to { opacity: 1; transform: translateY(0); }
                }
                @keyframes slideUp {
                    from { opacity: 0; transform: translateY(20px); }
                    to { opacity: 1; transform: translateY(0); }
                }

                .filter-bright {
                    filter: brightness(1.2); /* Adjust the brightness here */
                }

                /* Mouse Scroll Animation */
                .mouse-icon {
                    width: 2rem;
                    height: 3rem;
                    position: relative;
                    margin: 0 auto;
                }
                .mouse-body {
                    position: relative;
                    width: 2rem;
                    height: 3rem;
                    border-radius: 1.5rem;
                    border: 2px solid #5b2a2a;
                }
                .mouse-wheel {
                    width: 0.25rem;
                    height: 0.5rem;
                    background-color: #5b2a2a;
                    border-radius: 0.125rem;
                    position: absolute;
                    top: 0.75rem;
                    left: 50%;
                    transform: translateX(-50%);
                    animation: scroll 1.5s infinite;
                }
                @keyframes scroll {
                    0% {
                        opacity: 1;
                        transform: translateY(0) translateX(-50%);
                    }
                    50% {
                        opacity: 0;
                        transform: translateY(1rem) translateX(-50%);
                    }
                    100% {
                        opacity: 1;
                        transform: translateY(0) translateX(-50%);
                    }
                }
            `}</style>
        </>
    )
}

export default Hero;
