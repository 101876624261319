import React, { useState, useEffect } from 'react';
import NavLinks from '../Navbar/NavLinks';
import { HashLink } from 'react-router-hash-link';
import logo from '../../images/logo__3_-removebg-preview.png'; // Import the logo

const NavBar = () => {
    const [top, setTop] = useState(!window.scrollY);
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const scrollHandler = () => {
            window.pageYOffset > 10 ? setTop(false) : setTop(true);
        };
        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    }, [top]);

    return (
        <nav className={`fixed top-0 w-full z-50 transition duration-300 ease-in-out ${!top && 'bg-white shadow-lg'}`}>
            <div className="flex flex-row justify-between items-center py-4 px-6 md:px-16">
                {/* Logo */}
                <div className="flex items-center">
                    <HashLink smooth to="/#hero" className="flex items-center">
                        <img src={logo} alt="Texcellence Logo" className="h-28 w-auto mr-3" />  {/* Increased logo height */}
                    </HashLink>
                </div>

                {/* Navigation Links */}
                <div className="hidden lg:flex flex-row space-x-8 items-center">
                    <NavLinks />
                    <div className="text-maroon font-semibold text-lg">
                        Enquiry: <span className="text-red-600">+919819121689</span>
                    </div>
                </div>

                {/* Hamburger Menu for Mobile */}
                <button 
                    className="p-2 rounded-lg lg:hidden text-maroon hover:bg-maroon-dark transition-colors duration-300 z-50" 
                    onClick={handleClick}>
                    {isOpen ? (
                        <svg className="h-8 w-8 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path fillRule="evenodd" clipRule="evenodd" d="M6 18L18 6M6 6l12 12" /> {/* X icon */}
                        </svg>
                    ) : (
                        <svg className="h-8 w-8 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path fillRule="evenodd" d="M4 5h16a1 1 0 1 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 1 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 1 1 0 2H4a1 1 0 1 1 0-2z" /> {/* Hamburger icon */}
                        </svg>
                    )}
                </button>

                {/* Mobile Menu */}
                <div className={`fixed transition-transform duration-500 ease-in-out transform ${isOpen ? "translate-y-0 opacity-100" : "-translate-y-full opacity-0"} flex justify-center left-0 w-full h-auto rounded-md p-12 bg-white lg:hidden shadow-xl top-14 z-40`}>
                    <div className="flex flex-col space-y-6 items-center">
                        <NavLinks />
                        <div className="text-maroon font-bold text-lg">
                            Enquiry: <span className="text-red-600">+919819121689</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Custom Styles */}
            <style jsx>{`
                .NavLinks a {
                    color: inherit;
                    text-decoration: none;
                    transition: color 0.3s, border-color 0.3s;
                }

                .NavLinks a:hover {
                    color: #5b2a2a !important; /* Maroon color on hover */
                    border-color: #5b2a2a !important; /* Maroon border on hover */
                }

                .demo-button {
                    border: 2px solid transparent;
                    padding: 8px 16px;
                    border-radius: 4px;
                    transition: border-color 0.3s;
                }

                .demo-button:hover {
                    border-color: #5b2a2a; /* Maroon border on hover */
                }

                .text-maroon {
                    color: #5b2a2a;
                }

                .hover\\:bg-maroon-dark:hover {
                    background-color: #8B0000; /* Darker maroon background on hover */
                }
            `}</style>
        </nav>
    );
};

export default NavBar;
