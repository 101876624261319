import React from 'react';
import { Link } from 'react-router-dom';
import scourexImage from '../images/texcel-scourex-wetting-cum-scouring-chemical-for-cotton.webp';
import catasoftImage from '../images/Texcal Catasoft Cationic Fabric Softener Paste.jpg';
import dftImage from '../images/Texcel  DFT Polyester Fabric Yarn Dyeing Chemical.jpg';
import dyeewellImage from '../images/Texcel Dyeewell Polyester Dyeing Leveling Chemical.jpg';
import eliminatorGloImage from '../images/Texel Eliminator Glo Oligomer Polyester Dyeing Chemical.jpg';
import htImage from '../images/Texcel Desize HT Conc  Fabric Desizing Enzyme.jpg';
import defoamerImage from '../images/Texcel Eco Silicon Defoamer Chemical.jpg';
import awxImage from '../images/Texcel AWX Sectional Yarn Warping Oil Chemical.jpg';
import coningImage from '../images/Texcel Coning Eco Texturing Yarn Antistatic Lubricating Oil Chemical.jpg';
import polImage from '../images/Texcel Polyscouar  Polyester Scouring Wetting Agent Chemical.jpg';
import biosImage from '../images/Texcel Denim  Fabric Biowash Biopolish Enzyme.jpg';
import oilstandImage from '../images/Texcel Coning STD Texturing Yarn Antistatic Lubricating Oil.jpg';

const products = [
    { name: "Texcel® SCOUREX", image: scourexImage, link: "/products" },
    { name: "Texcel® CATASOFT", image: catasoftImage, link: "/products" },
    { name: "Texcel® DFT", image: dftImage, link: "/products" },
    { name: "Texcel® DYEEWELL", image: dyeewellImage, link: "/products" },
    { name: "Texcel® ELIMINATAR GLO", image: eliminatorGloImage, link: "/products" },
    { name: "Texcel® Desize HT", image: htImage, link: "/products" },
    { name: "Texcel® DEFOAMER", image: defoamerImage, link: "/products" },
    { name: "Texcel® AWX", image: awxImage, link: "/productsawx" },
    { name: "Texcel® CONING OIL-ECO", image: coningImage, link: "/products" },
    { name: "Texcel® POLYSCOUAR", image: polImage, link: "/products" },
    { name: "Texcel® BIOWASH ENZYME", image: biosImage, link: "/products" },
    { name: "Texcel® CONING OIL-STANDARD", image: oilstandImage, link: "/products" },
];
const Clients = () => {
    return (
        <div className="mt-8 bg-gray-50">
            <section data-aos="fade-up">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-4xl text-maroon uppercase font-bold">Our Products</h2>
                    <div className="flex justify-center">
                        <div className="w-24 border-b-4 border-maroon"></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-maroon">Discover some of our premium products.</h2>
                </div>

                <div className="overflow-hidden px-8">
                    <div className="scrolling-wrapper flex">
                        {products.map((product, index) => (
                            <Link key={index} to={product.link} className="product-tile">
                                <div className="product-card">
                                    <img src={product.image} alt={product.name} className="product-image" />
                                    <p className="product-name">{product.name}</p>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </section>

            <style jsx>{`
                .scrolling-wrapper {
                    display: flex;
                    animation: scroll 30s linear infinite;
                }

                .product-tile {
                    flex: 0 0 auto;
                    width: 220px;
                    text-align: center;
                    margin-right: 24px;
                    transition: transform 0.3s ease-in-out;
                    text-decoration: none;
                    color: inherit;
                }

                .product-card {
                    background-color: #fff;
                    border-radius: 12px;
                    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
                    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
                    overflow: hidden;
                    padding: 16px;
                }

                .product-tile:hover .product-card {
                    transform: translateY(-10px);
                    box-shadow: 0 12px 28px rgba(0, 0, 0, 0.15);
                }

                .product-image {
                    height: 150px;
                    width: 100%;
                    object-fit: cover;
                    margin-bottom: 12px;
                    border-radius: 8px;
                }

                .product-name {
                    font-size: 1.2rem;
                    color: #800000;
                    font-weight: 600;
                    padding: 8px 0;
                }

                @keyframes scroll {
                    0% {
                        transform: translateX(0);
                    }
                    100% {
                        transform: translateX(-100%);
                    }
                }

                .scrolling-wrapper:after {
                    content: '';
                    display: flex;
                    animation: scroll 30s linear infinite;
                }
            `}</style>
        </div>
    );
};

export default Clients;