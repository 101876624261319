import React, { useState, useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import scourexImage from '../images/texcel-scourex-wetting-cum-scouring-chemical-for-cotton.webp';
import catasoftImage from '../images/Texcal Catasoft Cationic Fabric Softener Paste.jpg';
import dftImage from '../images/Texcel  DFT Polyester Fabric Yarn Dyeing Chemical.jpg';
import dyeewellImage from '../images/Texcel Dyeewell Polyester Dyeing Leveling Chemical.jpg';
import eliminatorGloImage from '../images/Texel Eliminator Glo Oligomer Polyester Dyeing Chemical.jpg';
import heroBackground from '../images/fashio.webp';
import ht from '../images/Texcel Desize HT Conc  Fabric Desizing Enzyme.jpg'
import defoamer from '../images/Texcel Eco Silicon Defoamer Chemical.jpg'
import awx from '../images/Texcel AWX Sectional Yarn Warping Oil Chemical.jpg'
import coning from '../images/Texcel Coning Eco Texturing Yarn Antistatic Lubricating Oil Chemical.jpg'
import pol from '../images/Texcel Polyscouar  Polyester Scouring Wetting Agent Chemical.jpg'
import bios from '../images/Texcel Denim  Fabric Biowash Biopolish Enzyme.jpg'
import oilstand from '../images/Texcel Coning STD Texturing Yarn Antistatic Lubricating Oil.jpg'
import mouldReleaseSi from '../images/mouldReleaseSi.jpeg'
import sample from '../images/sample.jpeg'

const Products = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const chemicalsByCategory = {
        "Spin Finish": [
            {
                name: "Texcel® SF8900 LN",
                description: `
                    Texcel® SF8900 LN is an excellent spin finish oil for LINEN and its blend during the yarn spinning process. It is a synergic blend of surface-active ingredients, specifically designed for finer quality yarn of Linen and its blend. It operates in a wide range of temperatures, imparting a protective coating to the fiber surface. This helps in reducing static charges on the fiber and improves spinnability. It is environmentally safe and easy to handle manually. Applicable in fiber blending and damping stages.
                `,
                technicalDetails: `
                    Texcel® SF8900 LN provides excellent lubrication and protection during the spinning process. It prevents yellowness and shade changes while reducing friction between metal and fiber as well as fiber-to-fiber friction. It is stable in a wide pH range of process water and withstands high temperatures and hard water.
                `,
                benefits: [
                    "Imparts a protective coating to the fiber.",
                    "Acts as a lubricating agent during the spinning process.",
                    "No yellowness, shade change, or effect on whiteness.",
                    "Reduces metal-to-fiber and fiber-to-fiber friction.",
                    "Stable across a wide pH range and high temperatures.",
                    "Improves spinnability of the final yarn.",
                    "Recommended dosage: 1% to 2% by spray method during fiber blending/damping."
                ],
                expertTip: `
                    For optimal performance, apply Texcel® SF8900 LN at a dosage of 1% to 2% during fiber blending or damping. Ensure thorough application to achieve uniform coverage and maximize benefits.
                `,
                image: sample,  // Replace with the correct path
                brochure: "/path/to/sf8900-ln-brochure.pdf",
                getQuoteDetails: {
                    minimumOrderQuantity: "100 Kg",
                    packagingType: "Loose",
                    usage: "Fiber Blending/Damping",
                    shelfLife: "1 Year",
                    form: "Liquid",
                    storageInstructions: `
                        Store in a cool and dark place. Ensure the container is tightly sealed after each use to prevent contamination. Use personal protective equipment when handling the chemical.
                    `
                }
            },
            {
                name: "Texcel® SF8900 VS",
                description: `
                    Texcel® SF8900 VS is an excellent spin finish oil for viscose and its blends during the yarn spinning process. It is a synergistic blend of surface-active ingredients, specifically designed for finer quality yarns of viscose. It operates effectively across a wide temperature range and imparts a protective coating to the fiber surface. It helps in reducing static charges on the fiber and improves spinnability. The product is environmentally safe and easy to handle manually, making it suitable for use in fiber blending and damping stages.
                `,
                benefits: [
                    "Imparts protective coating to the fiber.",
                    "Acts as a lubricating agent during the spinning process.",
                    "No yellowness, shade change, or effect on whiteness.",
                    "Reduces metal-to-fiber and fiber-to-fiber friction.",
                    "Stable across a wide pH range of process water.",  
                    "Stable to high temperatures and hard water.",
                    "Improves spinnability of the final yarn.",
                    "Dosage: 1 to 2% by spray method during fiber blending/damping."
                ],
                image: sample,
                properties: [
                    "Active content: Blends of fatty acid bis diethyl phosphate esters.",
                    "Appearance: Flowable milky white liquid.",
                    "Solubility: Soluble in water.",
                    "Stability: Room temperature water soluble."
                ],
                storageHandlingSafety: `
                    Store in a cool and dark place. Use personal protective equipment like gloves, goggles, and masks. Ensure the container lid is closed properly after use.
                `,
                disclaimer: `
                    The data provided in this technical data sheet is based on experience and is believed to be reliable. It does not constitute part of our terms and conditions of sale, nor does it imply any warranty, express or implied, that the information or data are correct or comply with third-party patent claims.
                `
            }
        ],
        "Warping Chemicals": [
            {
                name: "Texcel® AWX PS",
                description: `
                    Texcel® AWX PS is an excellent warping oil for finer polyester spun sized yarn. It is also known as after waxing oil lubricant or warping oil. It imparts better cohesion of fibers and reduces the hairiness of yarn. It aligns all protruding surface fibers along the length of warp yarn. It provides a protective coating on yarn, which helps to improve the strength of the yarn. It has lubricity properties that minimize yarn breakage during the weaving process and provides antistatic properties to high-speed weaving machines, reducing needle breakages.
                `,
                technicalDetails: `
                    Texcel® AWX PS reduces yarn hairiness and provides cohesion to improve strength. It acts as a lubricant to minimize yarn breakages and reduces machine stoppages due to its antistatic property. It also reduces friction between yarn and metal, minimizing needle breakages. It is easily washable during the scouring process of the fabric.
                `,
                benefits: [
                    "Reduces hairiness of yarn.",
                    "Imparts cohesion to yarn, improving strength.",
                    "Acts as a lubricant to minimize yarn breakages.",
                    "Antistatic property reduces machine stoppages.",
                    "Reduces yarn-to-metal friction, minimizing needle breakages.",
                    "Easily washable during the scouring process of fabric."
                ],
                properties: [
                    "Active content: Blends of mineral oil.",
                    "Appearance: Pale yellow to brown.",
                    "Application: Pour directly into the applicator of the kiss roll at the sectional warping stage.",
                    "Stability: Stable to high temperatures and hard water."
                ],
                image: sample,  // Replace with the correct path
                brochure: "/path/to/awx-ps-brochure.pdf",
                getQuoteDetails: {
                    minimumOrderQuantity: "200 Kg",
                    packagingType: "Loose",
                    usage: "Warping",
                    shelfLife: "1 Year",
                    form: "Liquid",
                    storageInstructions: `
                        Store in a cool and dark place. Use personal protective equipment like gloves, goggles, and masks when handling. Ensure the container is tightly sealed after use.
                    `
                }
            },
            {
                name: "Texcel® AWX PC",
                description: `
                    Texcel® AWX PC is an excellent warping oil designed for finer polyester cotton (PC) spun sized yarn. Known as after-waxing oil lubricant or warping oil, it enhances fiber cohesion, reduces yarn hairiness, and aligns protruding surface fibers along the length of warp yarn. It provides a protective coating that improves yarn strength and lubricates to minimize yarn breakage during the weaving process. Additionally, it offers antistatic properties to high-speed weaving machines, reducing needle breakages.
                `,
                benefits: [
                    "Reduces hairiness of yarn.",
                    "Imparts cohesion to yarn, improving strength.",
                    "Acts as a lubricant to minimize yarn breakages.",
                    "Antistatic properties reduce machine stoppages.",
                    "Reduces yarn-to-metal friction, minimizing needle breakages.",
                    "Easily washable during the scouring process of fabric."
                ],
                image:sample,
                properties: [
                    "Active content: Blends of mineral oil and synthetic polymer.",
                    "Appearance: Pale yellow to brown.",
                    "Application: Pour directly into the applicator of kiss roll at sectional warping stage.",
                    "Stability: Stable to high temperatures and hard water."
                ],
                storageHandlingSafety: `
                    Store in a cool and dark place. Use personal protective equipment such as gloves, goggles, and masks. After use, close the lid of the container properly.
                `,
                disclaimer: `
                    The data provided in this technical data sheet is based on experience and is believed to be reliable. It does not constitute part of our terms and conditions of sale, nor does it imply any warranty, express or implied, that the information or data are correct or comply with third-party patent claims.
                `
            },
            {
                name: "Texcel® AWX CO",
                description: `
                    Texcel® AWX CO is an excellent warping oil designed for fine count cellulosic sized yarn. Known as after-waxing oil lubricant or warping oil, it improves fiber cohesion, reduces yarn hairiness, and aligns protruding surface fibers along the length of warp yarn. It provides a protective coating on yarn, enhancing its strength and lubricating to minimize yarn breakage during the weaving process. Additionally, it offers antistatic properties to high-speed weaving machines, reducing needle breakages.
                `,
                benefits: [
                    "Reduces hairiness of yarn.",
                    "Imparts cohesion to yarn, improving strength.",
                    "Acts as a lubricant to minimize yarn breakages.",
                    "Antistatic properties reduce machine stoppages.",
                    "Reduces yarn-to-metal friction, minimizing needle breakages.",
                    "Easily washable during the scouring process of fabric."
                ],
                image: sample,
                properties: [
                    "Active content: Blends of synthetic polymeric derivatives.",
                    "Appearance: Colorless to slightly pale yellow.",
                    "Application: Pour directly into the applicator of kiss roll at sectional warping stage.",
                    "Stability: Stable to high temperatures and hard water."
                ],
                storageHandlingSafety: `
                    Store in a cool and dark place. Use personal protective equipment such as gloves, goggles, and masks. After use, close the lid of the container properly.
                `,
                disclaimer: `
                    The data provided in this technical data sheet is based on experience and is believed to be reliable. It does not constitute part of our terms and conditions of sale, nor does it imply any warranty, express or implied, that the information or data are correct or comply with third-party patent claims.
                `
            },
            {
                name: "Texcel® AWX VS",
                description: `
                    Texcel® AWX VS is an excellent warping oil for fine count viscose and its blend-sized yarn. Known as after-waxing oil lubricant or warping oil, it improves fiber cohesion, reduces yarn hairiness, and aligns protruding surface fibers along the length of warp yarn. It provides a protective coating on the yarn, enhancing its strength and lubricating to minimize yarn breakage during the weaving process. Additionally, it offers antistatic properties to high-speed weaving machines, reducing needle breakages.
                `,
                benefits: [
                    "Reduces hairiness of yarn.",
                    "Imparts cohesion to yarn, improving strength.",
                    "Acts as a lubricant to minimize yarn breakages.",
                    "Antistatic properties reduce machine stoppages.",
                    "Reduces yarn-to-metal friction, minimizing needle breakages.",
                    "Easily washable during the scouring process of fabric."
                ],
                image:sample,
                properties: [
                    "Active content: Blends of synthetic polymeric derivatives.",
                    "Appearance: Colorless to slightly pale yellow.",
                    "Application: Pour directly into the applicator of kiss roll at sectional warping stage.",
                    "Stability: Stable to high temperatures and hard water."
                ],
                storageHandlingSafety: `
                    Store in a cool and dark place. Use personal protective equipment such as gloves, goggles, and masks. After use, close the lid of the container properly.
                `,
                disclaimer: `
                    The data provided in this technical data sheet is based on experience and is believed to be reliable. It does not constitute part of our terms and conditions of sale, nor does it imply any warranty, express or implied, that the information or data are correct or comply with third-party patent claims.
                `
            }
        ],
        "Pre-Treatment": [
            {
                name: "Texcel® SCOUREX",
                description: `
                    Texcel® SCOUREX is a premium low foam scouring agent, engineered specifically for the textile industry. 
                    It imparts excellent wetting and rewetting properties and boasts a stable emulsification and dispersion 
                    property during the scouring and bleaching stages. By facilitating the penetration of alkali and peroxide, 
                    Texcel® SCOUREX ensures an effective bleaching process, resulting in fabrics that are not only perfectly 
                    white but also exhibit superior water absorbency.
                `,
                technicalDetails: `
                    Texcel® SCOUREX is particularly effective in processes involving high temperatures and shear forces, 
                    making it an ideal choice for various fabric types including 100% cotton, 100% polyester, and their blends. 
                    Its non-ionic nature and ability to maintain stability under varying conditions ensure consistent results 
                    across different stages of textile processing.
                `,
                benefits: [
                    "Excellent wetting and rewetting properties.",
                    "Stable emulsification and dispersion.",
                    "High-temperature stability.",
                    "Effective penetration of alkali and peroxide.",
                    "Applicable to various fabric types."
                ],
                expertTip: `
                    For best results, use Texcel® SCOUREX at a concentration of 0.5% to 1% on the weight of the fabric. 
                    Adjust the temperature and process duration based on the fabric type to optimize softness, durability, 
                    and whiteness.
                `,
                image: scourexImage,
                brochure: "/path/to/scourex-brochure.pdf",
                getQuoteDetails: {
                    minimumOrderQuantity: "50 Kg",
                    packagingType: "Carboy",
                    usage: "Textile Industry",
                    shelfLife: "1 Year",
                    form: "Liquid",
                   
                    storageInstructions: `
                        Store in a cool and dark place. Ensure the lid of the container is closed tightly after each use 
                        to prevent contamination. Personal protective equipment such as gloves, goggles, and masks 
                        should be worn while handling the chemical.
                    `
                }
            },
            {
                name: "Texcel® DESIZE-HT STD",
                description: `
                    Texcel® DESIZE-HT STD is a high-temperature active desizing enzyme. It is an alpha-amylase enzyme produced through the submerged fermentation of *Bacillus subtilis* and extracted using modern techniques. This enzyme effectively breaks down starch molecules into water-soluble amino byproducts, which are easily dispersed and washed off. It is suitable for use in pad batch, exhaust jigger, jet machines, and continuous bleaching range (CBR) processes.
                `,
                benefits: [
                    "Improves the TAGWA rating of desized fabric.",
                    "Converts starch into water-soluble byproducts.",
                    "Suitable for 100% cotton, 100% viscose, and blended fabrics.",
                    "Compatible with wetting and scouring agents.",
                    "Applicable in exhaust and continuous bleaching range (CBR)."
                ],
                image:sample,
                properties: [
                    "Appearance: Dark brown.",
                    "Odor: Slight fermentation odor.",
                    "Dosage (owf): 0.7% to 1%.",
                    "Working temperature: 85°C to 95°C.",
                    "Solubility: Soluble in water."
                ],
                storageHandlingSafety: `
                    Store in a cool and dark place. Use personal protective equipment such as gloves, goggles, and masks. After use, close the lid of the can/drum/bag properly.
                `
            },
            {
                name: "Texcel® DMIN",
                description: `
                    Texcel® DMIN is a demineralizing agent specifically designed for the RFD white fabric and surgical cotton processing industries. It helps reduce the sulphated ash content of bleached cotton and prevents the wasteful decomposition of hydrogen peroxide due to the presence of metal ions in the bleach bath. It can be added to water storage tanks or during the bleaching process, improving whiteness and reducing inorganic impurities in raw cotton.
                `,
                benefits: [
                    "Reduces sulphated ash content.",
                    "Improves whiteness index.",
                    "Promotes effective activation of hydrogen peroxide stabilizer.",
                    "Prevents redeposition of washed impurities.",
                    "Ensures bleached cotton meets IP & US FDA quality norms."
                ],
                image: sample,
                properties: [
                    "Active content: Organic compound.",
                    "Appearance: Colorless to pale yellow.",
                    "Dosage: 0.5% to 1%.",
                    "Solubility: Soluble in water.",
                    "Stability: Stable in hard water."
                ],
                storageHandlingSafety: `
                    Store in a cool and dark place. Use personal protective equipment such as gloves, goggles, and masks. After use, close the lid of the can/drum properly.
                `
            },
            {
                name: "Texcel® Desize HT Conc Fabric Desizing Enzyme",
                description: `
                    Texcel® DESIZE-HT Conc. is a powerful, high-temperature active desizing enzyme. 
                    It is an alpha-amylase enzyme produced by submerged fermentation of Bacillus subtilis 
                    and extraction with modern techniques. It effectively disintegrates starch molecules 
                    into water-soluble amino byproducts, which are easily dispersible and washed off. 
                    Applicable in pad batch, exhaust jigger, and jet machine processes, as well as continuous 
                    bleaching range (CBR) processes.
                `,
                technicalDetails: `
                    Texcel® DESIZE-HT Conc. works effectively at high temperatures, making it ideal for 
                    processes that require rigorous starch removal. The enzyme is stable and consistent 
                    across different textile processing stages, ensuring thorough desizing with minimal 
                    fabric damage.
                `,
                benefits: [
                    "Effective high-temperature desizing.",
                    "Stable and consistent enzyme activity.",
                    "Minimal fabric damage.",
                    "Applicable in various processing methods."
                ],
                expertTip: `
                    Use Texcel® DESIZE-HT Conc. at a dosage of 0.3% for optimal results. Ensure proper 
                    temperature control to maximize enzyme activity and achieve thorough starch removal.
                `,
                image: ht,
                brochure: "/path/to/desize-ht-brochure.pdf",
                getQuoteDetails: {
                    minimumOrderQuantity: "25 Kg",
                    packagingType: "Loose",
                    usage: "Textile Industry",
                    shelfLife: "12 Months",
                    form: "Liquid",
                    storageInstructions: `
                        Store in a cool and dark place. Ensure the container is tightly sealed to prevent 
                        contamination and degradation of enzyme activity. Use appropriate protective equipment 
                        when handling.
                    `
                }
            },
            {
                name: "Texcel® Polyscouar Polyester Scouring Wetting Agent Chemical",
                description: `
                    Texcel® POLYSCOUAR is powerful low foam scouring agent, it imparts excellent wetting cum rewetting properties. 
                    It has stable emulsification as well as dispersion property at scouring and bleaching stage. 
                    It helps in penetration of alkali and peroxide for effective bleaching process. Bleached fabric 
                    shows excellent whiteness and water absorbency i.e rewetting property.
                `,
                technicalDetails: `
                    Texcel® POLYSCOUAR is particularly effective for use in the pretreatment stage of textile processing, 
                    where it ensures that fabrics achieve the desired level of cleanliness and absorbency. Its stability 
                    under various processing conditions makes it a versatile choice.
                `,
                benefits: [
                    "Low foam scouring agent.",
                    "Excellent wetting and rewetting properties.",
                    "Stable emulsification and dispersion.",
                    "Effective for alkali and peroxide penetration.",
                    "Enhances fabric whiteness and absorbency."
                ],
                expertTip: `
                    Use Texcel® POLYSCOUAR at concentrations between 0.5% to 1% depending on fabric weight and type. 
                    Ensure that scouring conditions are optimized for maximum fabric performance.
                `,
                image: pol,  // Replace with the correct path
                brochure: "/path/to/polyscouar-brochure.pdf",
                getQuoteDetails: {
                    minimumOrderQuantity: "50 Kg",
                    packagingType: "Loose",
                    usage: "Textile Industry",
                    shelfLife: "12 Months",
                    form: "Liquid",
                    storageInstructions: `
                        Store in a cool and dry place. Avoid exposure to direct sunlight. 
                        Keep the container tightly closed when not in use.
                    `
                }
            },
            {
                name: "Texcel® Eco Silicon Defoamer Chemical",
                description: `
                    Texcel® DEFOAMER is a powerful defoaming and deaeration agent. It assists in effective 
                    penetration of functional textile auxiliaries during pre-treatment and dyeing processes. 
                    The product effectively breaks down foam during processing, ensuring smooth and efficient 
                    treatment.
                `,
                technicalDetails: `
                    Texcel® DEFOAMER is compatible with a wide range of textile processing chemicals and 
                    ensures consistent performance under various conditions. Its formulation helps maintain 
                    the stability of processing baths, leading to uniform treatment and reduced defects.
                `,
                benefits: [
                    "Effective defoaming and deaeration.",
                    "Improves penetration of textile auxiliaries.",
                    "Ensures stable processing baths.",
                    "Reduces processing defects."
                ],
                expertTip: `
                    For optimal results, use Texcel® DEFOAMER at a dosage of 0.1% to 0.5% depending on the 
                    severity of foaming. Adjust the dosage based on bath conditions to achieve the best 
                    defoaming effect.
                `,
                image: defoamer,
                brochure: "/path/to/defoamer-brochure.pdf",
                getQuoteDetails: {
                    minimumOrderQuantity: "50 Kg",
                    packagingType: "Loose",
                    usage: "Textile Industry",
                    shelfLife: "12 Months",
                    form: "Liquid",
                    storageInstructions: `
                        Store in a cool and dry place. Keep the container tightly sealed to prevent contamination. 
                        Use appropriate protective equipment when handling the chemical.
                    `
                }
            },
            {
                name: "Texcel® BIOWASH ENZYME",
                description: `
                    Texcel® BIOWASH ENZYME is specially developed for improving graining effects for indigo-dyed 
                    washed denim fabric/Jean garments. It is effective on 100% cotton and its blended garments 
                    and fabric. The enzyme catalytically dissolves the protruding fibers of the garment surface 
                    to achieve a clear grainy look. It is a cellulosic enzyme formulation with pH buffering agents.
                `,
                technicalDetails: `
                    Texcel® BIOWASH ENZYME is designed for application in denim finishing processes where a grainy 
                    surface effect is desired. The enzyme formulation ensures precise and controlled fiber removal, 
                    enhancing the visual and tactile quality of denim products.
                `,
                benefits: [
                    "Improves graining effects on denim.",
                    "Effective on 100% cotton and blends.",
                    "Ensures controlled fiber removal.",
                    "Enhances visual and tactile quality of fabrics."
                ],
                expertTip: `
                    Use Texcel® BIOWASH ENZYME at a dosage of 1% for optimal results. 
                `,
                image: bios,
                brochure: "/path/to/biowash-enzyme-brochure.pdf",
                getQuoteDetails: {
                    minimumOrderQuantity: "25 Kg",
                    packagingType: "Loose",
                    usage: "Textile Industry",
                    shelfLife: "12 Months",
                    form: "Liquid",
                    storageInstructions: `
                        Store in a cool and dry place. Ensure the container is tightly sealed to prevent contamination. 
                        Use appropriate protective equipment when handling the chemical.
                    `
                }
            }
        ],
        "Finishing": [
            {
                name: "Texcel® CATASOFT",
                description: `
                    Texcel® CATASOFT is an excellent cold water soluble cationic softener paste. Effective for cotton, 
                    terry cotton knitted blend fabrics, and garment processing industry. It has a very good hand feeling 
                    and antistatic properties. Because of its low-yellowing specific characteristic, it is very suitable 
                    for blue and white jeans laundry or stone washing processing.
                `,
                technicalDetails: `
                    Texcel® CATASOFT is ideal for use in the finishing process of fabrics, providing a soft, smooth feel 
                    while maintaining the fabric's durability. Its cationic nature ensures a strong affinity with the fabric, 
                    ensuring long-lasting softness and antistatic effects.
                `,
                benefits: [
                    "Cold water soluble.",
                    "Excellent hand feeling.",
                    "Antistatic properties.",
                    "Low-yellowing, ideal for blue and white fabrics.",
                    "Suitable for various fabric types."
                ],
                expertTip: `
                    For best results, use Texcel® CATASOFT at a concentration of 0.5% to 1.5% on the weight of the fabric. 
                    It can be used in the final rinsing process to impart a soft finish to the fabric.
                `,
                image: catasoftImage,
                brochure: "/path/to/catasoft-brochure.pdf",
                getQuoteDetails: {
                    minimumOrderQuantity: "50 Kg",
                    packagingType: "Loose",
                    usage: "Fabric Softner",
                    shelfLife: "1 Year",
                    form: "Paste",
                    
                    storageInstructions: `
                        Store in a cool, dry place away from direct sunlight. Ensure the container is tightly sealed 
                        after use to prevent moisture absorption. Wear appropriate protective equipment when handling.
                    `
                }
            }
        ],
        "Dyeing": [
            {
                name: "Texcel® DFT",
                description: `
                    Texcel® DFT functions as both dispersing auxiliary and leveling agent in polyester dyeing. Concerning high 
                    temperature dyeing, the process is usually carried out in acidic conditions with addition of acetic 
                    acid under pressure.
                `,
                technicalDetails: `
                    Texcel® DFT is suitable for use in the dyeing of polyester fabrics, providing even color distribution and 
                    enhanced fabric feel. It is particularly effective in processes involving high temperatures, where maintaining 
                    color consistency is critical.
                `,
                benefits: [
                    "Functions as both dispersing auxiliary and leveling agent.",
                    "Effective in high-temperature dyeing processes.",
                    "Ensures even color distribution across polyester fabrics.",
                    "Compatible with acidic dyeing conditions."
                ],
                expertTip: `
                    Use Texcel® DFT at a concentration of 1% to 2% on the weight of the fabric. Ensure that the pH is maintained 
                    between 4 and 5 during the dyeing process for optimal results.
                `,
                image: dftImage,
                brochure: "/path/to/dft-brochure.pdf",
                getQuoteDetails: {
                    minimumOrderQuantity: "50 Kg",
                    packagingType: "Loose",
                    usage: "Dyeing Process",
                    shelfLife: "6 Months",
                    form: "Liquid",
                    
                    storageInstructions: `
                        Store in a cool and dry place. Avoid exposure to direct sunlight. Ensure containers are tightly sealed 
                        to prevent contamination. Use protective gear such as gloves and goggles when handling.
                    `
                }
            },
            {
                name: "Texcel® Dyeewell Polyester Dyeing Leveling Chemical",
                description: `
                    Texcel® DYEEWELL functions as a leveling agent in polyester dyeing. It is particularly useful in preventing 
                    excessively rapid absorption and counteracting the migration of oligomers typical of PES fibers.
                `,
                technicalDetails: `
                    Texcel® DYEEWELL is designed for use in the dyeing of polyester fabrics, helping to maintain even color 
                    distribution and preventing issues related to the migration of oligomers. This product ensures a smooth 
                    and consistent dyeing process, particularly in high-temperature dyeing conditions.
                `,
                benefits: [
                    "Effective leveling agent.",
                    "Prevents rapid absorption during dyeing.",
                    "Counteracts oligomer migration.",
                    "Ensures even color distribution across polyester fabrics."
                ],
                expertTip: `
                    Use Texcel® DYEEWELL at a concentration of 0.8% to 1.5% on the weight of the fabric. Maintain dyeing conditions 
                    within recommended temperature and pH levels for best results.
                `,
                image: dyeewellImage,
                brochure: "/path/to/dyeewell-brochure.pdf",
                getQuoteDetails: {
                    minimumOrderQuantity: "50 Kg",
                    packagingType: "Loose",
                    usage: "Leveling",
                    shelfLife: "12 Months",
                    form: "Liquid",
                    
                    storageInstructions: `
                        Store in a cool, dry place away from direct sunlight. Ensure the container is tightly sealed 
                        after use to prevent contamination. Use protective gear when handling.
                    `
                }
            },
            {
                name: "Texcel® ELIMINATAR GLO",
                description: `
                    Texcel® ELIMINATAR GLO is specially developed to remove oligomers in the dyeing of polyester-based substrates 
                    like fiber tops, yarn dyeing in package form, and fabric dyeing in Jet as well as Soft flow dyeing machines.
                `,
                technicalDetails: `
                    Texcel® ELIMINATAR GLO is effective in eliminating oligomer-related issues during polyester dyeing, 
                    ensuring a clean dyeing process and improved fabric quality. Its eco-friendly formulation is suitable 
                    for various polyester dyeing applications.
                `,
                benefits: [
                    "Removes oligomers effectively.",
                    "Suitable for various polyester dyeing processes.",
                    "Improves fabric quality by eliminating oligomer buildup.",
                    "Eco-friendly formulation."
                ],
                expertTip: `
                    For optimal results, use Texcel® ELIMINATAR GLO at a concentration of 0.5% to 1.0% on the weight of the fabric. 
                    Ensure thorough mixing during the dyeing process to achieve consistent fabric quality.
                `,
                image: eliminatorGloImage,
                brochure: "/path/to/eliminator-glo-brochure.pdf",
                getQuoteDetails: {
                    minimumOrderQuantity: "50 Kg",
                    packagingType: "Loose",
                    usage: "Polyester Dyeing",
                    shelfLife: "6 Months",
                    form: "Liquid",
                   
                    storageInstructions: `
                        Store in a cool, dry place. Keep containers tightly sealed to prevent contamination. 
                        Use personal protective equipment during handling.
                    `
                }
            }
        ],
        "Sizing Chemicals": [
        {
            name: "Texcel® PRIMASOFT LUBE",
            description: `
                Texcel® PRIMASOFT LUBE is an excellent cold water-soluble synthetic plant-based polymeric lubricant paste, designed as a perfect replacement for animal fat-based lubricants. It provides superior penetration in the yarn core, imparting flexibility and antistatic properties to sized yarn. This lubricant improves abrasion resistance required for high-speed looms, avoids stickiness on looms, and enhances lubricity and fiber-to-metal abrasion resistance. It is suitable for polyester filament, polyester finished yarn, nylon filament, nylon finished yarn, acetate yarn, rayon filament, and cellulosic blends of spun yarn.
            `,
            benefits: [
                "Acts as an antistatic agent for warp sizing of man-made and synthetic yarns and their blends.",
                "Prevents generation of static electricity during sizing, warping, and weaving processes.",
                "Improves lubricity and fiber-to-metal abrasion resistance.",
                "Easily removable during desizing by ordinary scouring.",
                "Effective at low dosages of 0.2 – 0.5% in size solutions for polyester, nylon, and their blends."
            ],
            image:sample,
            properties: [
                "Active content: Plant-based fatty acid derivatives.",
                "Appearance: Pale yellow paste.",
                "Solubility: Soluble in water; soak in room temperature water for 4 to 5 hours.",
                "Stability: Stable to high temperatures and hard water."
            ],
            storageHandlingSafety: `
                Store in a cool and dark place. Use personal protective equipment such as gloves and goggles.
            `,
            disclaimer: `
                The data provided in this technical data sheet is based on experience and is believed to be reliable. It does not constitute part of our terms and conditions of sale, nor does it imply any warranty, express or implied, that the information or data are correct or comply with third-party patent claims.
            `
            },
            {
                name: "Texcel® MEGA SIZE BINDER",
                description: `
                    Texcel® MEGA SIZE BINDER is an intermediate blend of synthetic polymeric warp sizing agents, suitable for use alone or in combination with other water-soluble polymers, natural, and synthetic agents. Recommended for sizing cotton, rayon, polyester, and blended staple yarns, it can replace 30% to 50% of modified starch powder in the sizing bath, depending on yarn count. This premium warp sizing agent is designed for the sizing of open-end polyester/cotton blended yarns for high-speed weaving machines.
                `,
                benefits: [
                    "Cooks quickly and uniformly, easy to handle.",
                    "Minimizes shed on the slasher and loom.",
                    "Improves yarn strength and reduces hairiness.",
                    "Reduces warp and weft stops, enhancing weaving performance.",
                    "Increases the weight of yarn.",
                    "Washes off readily under normal pretreatment desizing conditions."
                ],
                image:sample,
                properties: [
                    "Active content: Blends of synthetic polymers.",
                    "Appearance: Flowable translucent white paste.",
                    "Solubility: Easily water soluble."
                ],
                preparation: `
                    Should be slowly added to the starting water with good agitation. Mix and cook according to the normal starch cooking procedure.
                `,
                storageHandlingSafety: `
                    Store in a cool and dark place. Use personal protective equipment such as gloves, goggles, and masks. After use, close the lid of the can/drum/bag properly.
                `
            }
        ],
        "Specialty Chemicals/Conning Oil": [
            {
                name: "Texcel® Coning Eco Texturing Yarn Antistatic Lubricating Oil Chemical",
                description: `
                    Texcel® CONING OIL-STANDARD is an excellent Antistatic lubricating conning oil. It is applicable to all types of dyed yarn and especially for polyester texturized yarn at the final winding process. It is derived from highly refined base oil with additives, which make it more absorbent to the yarn and helps to gain in yarn weight. It has heat absorbing property, which minimizes yarn breakage during the weaving process. It has excellent washability.
                `,
                technicalDetails: `
                    Texcel® CONING OIL-STANDARD is designed for use in the yarn texturizing process. It provides excellent lubrication, minimizing yarn breakage and enhancing the overall quality of the yarn. The oil's antistatic properties ensure smooth processing and prevent the accumulation of static electricity.
                `,
                benefits: [
                    "Antistatic properties.",
                    "Heat absorbing, reduces yarn breakage.",
                    "Excellent washability.",
                    "Enhances yarn weight."
                ],
                expertTip: `
                    For optimal results, use Texcel® CONING OIL-STANDARD at a dosage of 1% to 3% on the weight of the yarn. Adjust based on the specific yarn and process requirements.
                `,
                image: coning,
                brochure: "/path/to/coning-oil-standard-brochure.pdf",
                getQuoteDetails: {
                    minimumOrderQuantity: "200 Kg",
                    packagingType: "Loose",
                    usage: "Yarn Texturizing",
                    shelfLife: "1 Year",
                    form: "Liquid",
                    storageInstructions: `
                        Store in a cool and dry place. Ensure the container is tightly sealed to prevent contamination. 
                        Use appropriate protective equipment when handling the chemical.
                    `
                }
            },
            {
                name: "Texcel® AWX Sectional Yarn Warping Oil Chemical",
                description: `
                    Texcel® AWX is a blend of synthetic materials primarily based on fatty acid polymeric chemistry (non-acrylic). Compared to conventional starch & acrylic sizing materials, it is ready-to-use at room temperature. It is easily removed from the fabrics during the desizing process, even after heat setting of synthetic fabric it is easily washable.
                `,
                technicalDetails: `
                    Texcel® AWX provides excellent lubrication and protection to the yarn during the warping process. Its unique formulation ensures easy removal during desizing, reducing the need for harsh chemicals and maintaining the integrity of the fabric.
                `,
                benefits: [
                    "Ready-to-use at room temperature.",
                    "Easily washable after heat setting.",
                    "Reduces the need for harsh chemicals in desizing.",
                    "Protects yarn during the warping process."
                ],
                expertTip: `
                    For optimal results, use Texcel® AWX at a dosage of 1% to 3% on the weight of the yarn. Ensure thorough mixing and application to achieve uniform coverage.
                `,
                image: awx,
                brochure: "/path/to/awx-brochure.pdf",
                getQuoteDetails: {
                    minimumOrderQuantity: "50 Kg",
                    packagingType: "Loose",
                    usage: "Yarn Warping",
                    shelfLife: "1 Year",
                    form: "Liquid",
                    storageInstructions: `
                        Store in a cool and dry place. Ensure the container is tightly sealed to prevent contamination. 
                        Use appropriate protective equipment when handling the chemical.
                    `
                }
            },
            {
                name: "Texcel® Coning STD Texturing Yarn Antistatic Lubricating Oil",
                description: `
                    Texcel® CONING OIL-STANDARD is an excellent Antistatic lubricating conning oil. 
                    It is applicable to all types of dyed yarn and specially for polyester texturized yarn at final winding process. 
                    It is derived from highly refined base oil with additives, which make it more absorbent to the yarn and helps to gain in yarn weight. 
                    It has heat absorbing property, which minimizes yarn breakage during weaving process. 
                    It has excellent washability.
                `,
                technicalDetails: `
                    Texcel® CONING OIL-STANDARD is formulated to provide optimal antistatic and lubricating properties 
                    during the final winding process. Its refined base oil ensures enhanced absorbency and yarn performance.
                `,
                benefits: [
                    "Excellent antistatic lubricating oil.",
                    "Ideal for polyester texturized yarn.",
                    "Minimizes yarn breakage during weaving.",
                    "Improves yarn weight and performance.",
                    "Excellent washability."
                ],
                expertTip: `
                    Use Texcel® CONING OIL-STANDARD at the recommended dosage during the final winding process 
                    to enhance yarn quality and reduce breakage.
                `,
                image: oilstand,  // Replace with the correct path
                brochure: "/path/to/coning-oil-standard-brochure.pdf",
                getQuoteDetails: {
                    minimumOrderQuantity: "200 Kg",
                    packagingType: "Loose",
                    usage: "Yarn",
                    shelfLife: "1 Year",
                    form: "Liquid",
                    productCode: "TX108",
                    storageInstructions: `
                        Store in a cool, dry place. Ensure the container is tightly closed after each use 
                        to prevent contamination. Use personal protective equipment when handling.
                    `
                }
            },
            {
                name: "Texcel® MOULD RELEASE SI",
                description: `
                    Texcel® MOULD RELEASE SI is an excellent high-temperature stable Poly Dimethyl Siloxane fluid (silicone-based) in water emulsion. 
                    The silicone oil phase is compatible with many organic finishing agents such as paints, coatings, and adhesives. 
                    Mould release agent can be recommended for the release of articles where post-finishing is required.
                `,
                technicalDetails: `
                    Texcel® MOULD RELEASE SI is effective at lower concentrations and across a wide range of temperatures. 
                    It provides non-corrosive, non-reactive properties with no smoking, breakdown, carbonization, or build-up on molds and dies. 
                    The surface of the mold becomes abrasion-resistant and water-repellent with good emulsion stability.
                `,
                benefits: [
                    "Non-corrosive.",
                    "Non-reactive.",
                    "Effective at lower concentrations.",
                    "Effective across a wide range of temperatures.",
                    "No smoking, breakdown, carbonization, or build-up on molds and dies.",
                    "Abrasion-resistant and water-repellent mold surface.",
                    "Good emulsion stability."
                ],
                expertTip: `
                    Wipe, brush, or spray the emulsion, usually diluted. Recommended starting concentration is 2% to 5% (total solids of Mould Release Agent by water). 
                    Break in new or freshly cleaned molds by applying a light coat of undiluted release agent and running the empty mold through one or two heat cycles. 
                    After excess residual base fluid is wiped from the surface, the mold is ready for use.
                `,
                image: mouldReleaseSi,  // Replace with the correct path
                brochure: "/path/to/mould-release-si-brochure.pdf",
                getQuoteDetails: {
                    minimumOrderQuantity: "100 Kg",
                    packagingType: "Loose",
                    usage: "Mold Release",
                    shelfLife: "2 Years",
                    form: "Liquid",
                    storageInstructions: `
                        Store in a cool and dark place. Use personal protective equipment like gloves, goggles, and masks. 
                        After use, close the lid of the container properly.
                    `
                }
            }
        ]
    };

    const [selectedCategory, setSelectedCategory] = useState("Pre-Treatment");
    const [selectedChemical, setSelectedChemical] = useState(chemicalsByCategory[selectedCategory][0]);

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setSelectedChemical(chemicalsByCategory[category][0]);
    };

    const handleChemicalClick = (chemical) => {
        setSelectedChemical(chemical);
    };

    return (
        <div className="products-page">
            <NavBar />

            <div className="content-area">
                {/* Hero Section */}
                <section className="hero-section">
                    <div className="hero-overlay">
                        <div className="container">
                            <h1>Enhance Your Textile Production</h1>
                            <p>Precision chemistry for modern textile manufacturing.</p>
                            <button className="consultation-button">Request a Consultation</button>
                        </div>
                    </div>
                </section>

                {/* Categories Section */}
                <section className="categories-section">
                    <div className="container">
                        <h2 className="section-title">Textile & Dyes Auxiliaries</h2>
                        <p className="section-description">
                            Textile auxiliaries are chemical formulations used for preparing fabric for dyeing. The dyeing auxiliaries further make the process smoother and help in achieving the desired outcomes.
                        </p>
                        <div className="categories-buttons">
                            {Object.keys(chemicalsByCategory).map(category => (
                                <button
                                    key={category}
                                    onClick={() => handleCategoryClick(category)}
                                    className={`category-button ${selectedCategory === category ? 'active' : ''}`}
                                >
                                    {category}
                                </button>
                            ))}
                        </div>
                    </div>
                </section>

                {/* Main Content Section */}
                <section className="main-content-section">
                    <div className="container">
                        <div className="main-content-grid">
                            <div className="chemicals-grid">
                                {chemicalsByCategory[selectedCategory].map((chemical, index) => (
                                    <div
                                        key={index}
                                        className={`chemical-tile ${chemical === selectedChemical ? 'selected' : ''}`}
                                        onClick={() => handleChemicalClick(chemical)}
                                    >
                                        <img src={chemical.image} alt={chemical.name} className="chemical-image" />
                                        <h3>{chemical.name}</h3>
                                    </div>
                                ))}
                            </div>

                            {/* Product Details Section */}
                            {selectedChemical && (
                                <div className="product-details-section">
                                    <div className="product-header">
                                        <h2>{selectedChemical.name}</h2>
                                    </div>
                                    <div className="product-content">
                                        <img src={selectedChemical.image} alt={selectedChemical.name} className="product-image" />
                                        <div className="product-info">
                                            <h3>Description</h3>
                                            <p>{selectedChemical.description}</p>
                                            <h3>Technical Details</h3>
                                            <p>{selectedChemical.technicalDetails}</p>
                                            <h3>Benefits</h3>
                                            <ul>
                                                {selectedChemical.benefits.map((benefit, index) => (
                                                    <li key={index}>{benefit}</li>
                                                ))}
                                            </ul>
                                            <h3>Expert Tip</h3>
                                            <p>{selectedChemical.expertTip}</p>
                                            <a href={selectedChemical.brochure} download className="download-link">Download Brochure</a>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </section>

                {/* Get a Quote Section */}
                {selectedChemical && selectedChemical.getQuoteDetails && (
                    <section className="get-quote-section">
                        <div className="container">
                            <h2>Get a Quote for {selectedChemical.name}</h2>
                            <div className="quote-grid">
                                <div className="quote-item">
                                    <h4>Minimum Order Quantity</h4>
                                    <p>{selectedChemical.getQuoteDetails.minimumOrderQuantity}</p>
                                </div>
                                <div className="quote-item">
                                    <h4>Packaging Type</h4>
                                    <p>{selectedChemical.getQuoteDetails.packagingType}</p>
                                </div>
                                <div className="quote-item">
                                    <h4>Usage/Application</h4>
                                    <p>{selectedChemical.getQuoteDetails.usage}</p>
                                </div>
                                <div className="quote-item">
                                    <h4>Shelf Life</h4>
                                    <p>{selectedChemical.getQuoteDetails.shelfLife}</p>
                                </div>
                                <div className="quote-item">
                                    <h4>Form</h4>
                                    <p>{selectedChemical.getQuoteDetails.form}</p>
                                </div>
                                <div className="quote-item">
                                    <h4>pH Value</h4>
                                    <p>{selectedChemical.getQuoteDetails.phValue}</p>
                                </div>
                                <div className="quote-item">
                                    <h4>Purity</h4>
                                    <p>{selectedChemical.getQuoteDetails.purity}</p>
                                </div>
                            </div>
                            <div className="quote-storage-instructions">
                                <h4>Storage Instructions</h4>
                                <p>{selectedChemical.getQuoteDetails.storageInstructions}</p>
                            </div>
                            <button className="get-quote-button">Request a Quote</button>
                        </div>
                    </section>
                )}
            </div>

            <Footer />

            <style jsx>{`
/* Base Styles */
            .container {
                max-width: 1200px;
                margin: 0 auto;
                padding: 0 20px;
            }

            /* Hero Section */
            .hero-section {
                position: relative;
                height: 50vh;
                background: url(${heroBackground}) center/cover no-repeat;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
            }

            .hero-overlay {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
            }

            .hero-section h1 {
                font-size: 40px;
                margin-bottom: 20px;
            }

            .hero-section p {
                font-size: 18px;
                margin-bottom: 30px;
            }

            .consultation-button {
                background-color: #ff9900;
                color: #fff;
                padding: 14px 28px;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                font-size: 16px;
                transition: background-color 0.3s ease, transform 0.3s ease;
                box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
            }

            .consultation-button:hover {
                background-color: #e68a00;
                transform: scale(1.05);
            }

            /* Categories Section */
            .categories-section {
                background-color: #f9f9f9;
                padding: 20px;
                text-align: center;
            }

            .section-title {
                font-size: 2rem;
                color: #800000;
                margin-bottom: 20px;
            }

            .section-description {
                font-size: 1rem;
                color: #666;
                margin-bottom: 40px;
            }

            .categories-buttons {
                display: flex;
                justify-content: center;
                gap: 20px;
                flex-wrap: wrap;
            }

            .category-button {
                padding: 12px 24px;
                background-color: #800000;
                color: #fff;
                border: none;
                border-radius: 8px;
                cursor: pointer;
                transition: background-color 0.3s ease, transform 0.3s ease;
                box-shadow: 0px 4px 12px rgba(0, 61, 128, 0.2);
            }

            .category-button.active,
            .category-button:hover {
                background-color: #002966;
                transform: translateY(-2px);
            }

            /* Main Content Section */
            .main-content-section {
                padding: 60px 20px;
            }

            .main-content-grid {
                display: grid;
                grid-template-columns: 1fr 3fr;
                gap: 20px;
            }

            .chemicals-grid {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
                gap: 20px;
            }

            .chemical-tile {
                background-color: #fff;
                border: 2px solid transparent;
                border-radius: 10px;
                padding: 20px;
                text-align: center;
                cursor: pointer;
                transition: border-color 0.3s ease, transform 0.3s ease;
                box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
            }

            .chemical-tile.selected {
                border-color: #660000;
                transform: scale(1.05);
            }

            .chemical-tile:hover {
                border-color: #660000;
                transform: scale(1.05);
            }

            .chemical-image {
                max-width: 100%;
                border-radius: 10px;
                margin-bottom: 20px;
            }

            /* Product Details Section */
            .product-details-section {
                background-color: #fff;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
            }

            .product-header {
                margin-bottom: 20px;
            }

            .product-header h2 {
                font-size: 2rem;
                color: #660000;
            }

            .product-content {
                display: grid;
                grid-template-columns: 1fr 2fr;
                gap: 20px;
            }

            .product-image {
                max-width: 100%;
                border-radius: 10px;
                box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
            }

            .product-info h3 {
                font-size: 1.5rem;
                color: #660000;
                margin-bottom: 15px;
            }

            .product-info p {
                margin-bottom: 20px;
                line-height: 1.8;
                color: #555;
            }

            .product-info ul {
                list-style: disc;
                padding-left: 20px;
                color: #555;
                margin-bottom: 20px;
            }

            .product-info ul li {
                margin-bottom: 10px;
            }

            .download-link {
                display: inline-block;
                color: #800000;
                text-decoration: none;
                border-bottom: 1px solid #800000;
                font-size: 14px;
                transition: color 0.3s ease, border-color 0.3s ease;
            }

            .download-link:hover {
                color: #660000;
                border-color: #660000;
            }

            /* Get a Quote Section */
            .get-quote-section {
                background-color: #f7f7f7;
                padding: 60px 20px;
                border-radius: 10px;
                box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
                margin-bottom: 40px;
                text-align: center;
            }

            .get-quote-section h2 {
                font-size: 2rem;
                color: #660000;
                margin-bottom: 40px;
            }

            .quote-grid {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                gap: 20px;
                margin-bottom: 40px;
            }

            .quote-item {
                background-color: #fff;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
                text-align: center;
            }

            .quote-item h4 {
                font-size: 18px;
                margin-bottom: 10px;
                color: #333;
            }

            .quote-item p {
                font-size: 14px;
                color: #555;
            }

            .quote-storage-instructions {
                margin-bottom: 40px;
                text-align: center;
            }

            .quote-storage-instructions h4 {
                font-size: 18px;
                color: #333;
                margin-bottom: 10px;
            }

            .quote-storage-instructions p {
                font-size: 14px;
                color: #555;
                line-height: 1.8;
            }

            .get-quote-button {
                background-color: #ff9900;
                color: #fff;
                padding: 14px 28px;
                border: none;
                border-radius: 50px;
                cursor: pointer;
                font-size: 16px;
                transition: background-color 0.3s ease, transform 0.3s ease;
            }

            .get-quote-button:hover {
                background-color: #e68a00;
                transform: scale(1.05);
            }

            /* Responsive Design */
            @media (max-width: 768px) {
                .main-content-grid {
                    grid-template-columns: 1fr;
                }

                .product-content {
                    grid-template-columns: 1fr;
                }
            }

            `}</style>
        </div>
    );
};

export default Products;