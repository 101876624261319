import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    const linkStyles = {
        default: "px-4 font-extrabold text-gray-500 transition-colors duration-300",
        hover: "hover:text-[#5b2a2a]",  // Hex code for maroon color
        animated: "relative group"
    };

    return (
        <>
            <HashLink
                className={`${linkStyles.default} ${linkStyles.hover}`}
                smooth to="/#about"
            >
                About
            </HashLink>
            <HashLink
                className={`${linkStyles.default} ${linkStyles.hover}`}
                smooth to="/#services"
            >
                Services
            </HashLink>
            <HashLink
                className={`${linkStyles.default} ${linkStyles.animated}`}
                to="/products"
            >
                Products
                <span className="absolute inset-0 rounded-lg border-2 border-transparent animate-border"></span>
            </HashLink>
            <HashLink
                className={`${linkStyles.default} ${linkStyles.hover}`}
                to="/contact#contact"
            >
                Contact Us
            </HashLink>
            <HashLink
                className="text-white bg-[#5b2a2a] hover:bg-[#4e2323] inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl transition-colors duration-300"
                smooth to="/get-demo#demo"
            >
                Demo our products
            </HashLink>

            <style jsx>{`
                .animate-border {
                    border-color: #5b2a2a;
                    animation: pulse 1.5s infinite;
                    border-radius: 0.5rem;
                }

                @keyframes pulse {
                    0%, 100% {
                        border-color: transparent;
                    }
                    50% {
                        border-color: #5b2a2a;
                    }
                }
            `}</style>
        </>
    );
};

export default NavLinks;
