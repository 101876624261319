import React from 'react';
import placeholderImage from '../images/hands.jpg'; // Replace with your actual image path
import missionIcon from '../images/pexels.jpg'; // Replace with your actual icon
import visionIcon from '../images/pexels.jpg'; // Replace with your actual icon

const WhyTexcellence = () => {
    return (
        <div className="bg-white py-12 px-6 lg:px-20" id="why-texcellence">
            <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between">
                {/* Image Section */}
                <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
                    <img 
                        src={placeholderImage} 
                        alt="Why Choose Texcellence" 
                        className="w-full rounded-lg shadow-lg"
                    />
                </div>

                {/* Text Section */}
                <div className="w-full lg:w-1/2 lg:pl-12">
                    <h3 className="text-3xl text-maroon font-bold mb-4">Why Choose Texcellence?</h3>
                    <p className="text-lg text-gray-700 mb-6">
                        Texcellence Specialties Private Limited is dedicated to providing innovative chemical solutions that add value at every stage of the textile production process. We combine state-of-the-art facilities, cutting-edge research, and a team of skilled professionals to ensure that our products meet the highest standards of quality and sustainability.
                    </p>
                    
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                        <div className="bg-green-50 p-4 rounded-lg shadow-md flex items-start">
                            <img src={missionIcon} alt="Our Mission" className="w-12 h-12 mr-4" />
                            <div>
                                <h4 className="text-xl font-bold text-maroon">Our Mission</h4>
                                <p className="text-gray-600 mt-2">
                                    To be a global leader in textile chemicals, committed to sustainability and innovation.
                                </p>
                            </div>
                        </div>
                        <div className="bg-blue-50 p-4 rounded-lg shadow-md flex items-start">
                            <img src={visionIcon} alt="Our Vision" className="w-12 h-12 mr-4" />
                            <div>
                                <h4 className="text-xl font-bold text-blue-700">Our Vision</h4>
                                <p className="text-gray-600 mt-2">
                                    To spread the colors of excellence across the globe, enhancing the beauty and quality of textiles.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyTexcellence;
